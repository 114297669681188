import {processResponse} from "@/service/movement.service";

export const movementsModule = {
  state: () => ({
    movements: [],
    categories: []
  }),
  mutations: {
    setCategories(state, input) {
      state.categories = input
    },
    setMovements(state, input) {
      state.movements = input
    },
  },
  actions: {
    getMovements(context) {
      const uri = "/movement"
      context.getters.getAxiosInstance.get(uri)
        .then(value => {
          context.commit("setMovements", processResponse(value))
        })
        .catch(reason => context.dispatch("setError", reason.response.data.message))
    },
    getCategories(context) {
      const uri = "/category"
      context.getters.getAxiosInstance.get(uri)
        .then(value => {
          context.commit("setCategories", processResponse(value))
        })
        .catch(reason => context.dispatch("setError", reason.response.data.message))
    },
    createCategory(context, categoryTitle) {
      const uri = "/category"
      const payload = {}
      payload.categoryTitle = categoryTitle
      context.getters.getAxiosInstance.post(uri, payload)
        // eslint-disable-next-line no-unused-vars
        .then(value => {
          context.dispatch("getCategories")
        })
        .catch(reason => context.dispatch("setError", reason.response.data.message))
    },
    setCategoryToMovement(context, categoryTitle, movementsId) {
      const uri = "/movement/category"
      const payload = {
        movementsId: movementsId,
        categoryTitle: categoryTitle
      }
      payload.categoryTitle = categoryTitle
      context.getters.getAxiosInstance.post(uri, payload)
        // eslint-disable-next-line no-unused-vars
        .then(value => {
          context.dispatch("getCategories")
        })
        .catch(reason => context.dispatch("setError", reason.response.data.message))
    }
  },
}
