<template>
  <fieldset>
    <legend>Crear categoría</legend>
    <label for="category">Categoría</label>
    <input type="text" id="category" placeholder="Categoría" v-model="categoryTitle"/>
    <button class="primary" @click="sendCreateCategory">Crear</button>
  </fieldset>
</template>

<script>
import {useStore} from "vuex";
import {computed, ref} from "vue";

export default {
  name: "CreateCategoryForm",
  setup() {
    const store = useStore()
    let categories = computed(() => store.state.movement.categories)

    let createCategory = (categoryTitle) => {
      store.dispatch('createCategory', categoryTitle)
    }
    let categoryTitle = ref("");
    let sendCreateCategory = () => {
      if(categoryTitle.value !== "") {
        createCategory(categoryTitle.value)
      }
    }
    return {
      categories,
      sendCreateCategory,
      categoryTitle
    }
  }
}
</script>

<style scoped>

</style>
