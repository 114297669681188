import axios from "axios";

export default (token) => {
  const options = {};
  options.baseURL = process.env.VUE_APP_BASE_URL;
  options.headers = {}
  //? Decide add token or not
  if (token !== "") {
    options.headers.Authorization = 'bearer ' + token
  }
  return axios.create(options);
};
