<template>
  <div class="row">
    <div class="col-sm">
      <h3>Categorias</h3>
    </div>
    <div class="col-sm">
      <button class="tertiary" :disabled="selectedCategoryId === ''">Asignar</button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm">
      <div class="card fluid">
        <div class="section" v-for="(category, index) in categories" :key="index"
             @click="onClickCategory(category.id)" :class="{ dark: category.id===selectedCategoryId }">
          {{ category.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, ref} from "vue";

export default {
  name: "CategoryList",
  setup() {
    const store = useStore()
    let categories = computed(() => store.state.categories)
    let selectedCategoryId = ref("")
    let onClickCategory = (categoryId) => {
      if (categoryId === selectedCategoryId.value) {
        selectedCategoryId.value = ""
      } else {
        selectedCategoryId.value = categoryId
      }
    }
    return {
      categories,
      selectedCategoryId,
      onClickCategory
    }
  }
}
</script>

<style scoped>

</style>
