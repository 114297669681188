import {createStore} from 'vuex'
import {movementsModule} from './movement'
import axios from "axios";
import api from '../service/api';


export default createStore({
  state: {
    alertMsg: {
      type: 0,
      show: false,
      message: ""
    },
    token: ""
  },
  mutations: {
    setAlertMsg(state, input) {
      state.alertMsg = input
    },
    setShow(state, show) {
      state.alertMsg.show = show
    },
    setToken(state, token) {
      state.token = token
    },
    clearToken(state) {
      state.token = ""
    }
  },
  actions: {
    setError(context, message) {
      let alertMsg = {
        type: 1,
        show: true,
        message: message
      }
      context.commit("setAlertMsg", alertMsg)
      setTimeout(function () {
        context.commit("setShow", false);
      }, 3000);
    },
    login(context, payload) {
      const basePath = process.env.VUE_APP_BASE_URL
      const uri = "/auth/login"

      axios.post(basePath + uri, payload)
        .then(value => {
          context.commit("setToken", value.data.token)
        })
        .catch(reason => {
          context.commit("clearToken");
          context.dispatch("setError", reason.response.data.message);
        })
    }
  },
  getters: {
    isLogged: state => {
      return state.token !== ""
    },
    getAxiosInstance: state => {
      return api(state.token)
    }
  },
  modules: {
    movement: movementsModule
  }
})
