<template>
  <header id="header" v-if="isLogged">
    <router-link to="/" class="logo">Home</router-link> |
    <router-link to="/about" class="button">About</router-link> |
    <button>Download</button>
  </header>
  <router-view v-if="isLogged"/>
  <login v-if="!isLogged"></login>
  <span class="toast" :class="{red: alertMessage.type===1}" v-if="alertMessage.show">{{ alertMessage.message }}</span>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import Login from "@/views/Login";


export default {
  name: "App",
  components: {Login},
  setup() {
    const store = useStore()
    let alertMessage = computed(() => store.state.alertMsg)
    let isLogged = computed(() => store.getters.isLogged)
    return {
      alertMessage,
      isLogged
    }
  }
}
</script>

<style lang="scss">
#header {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.red{
  background-color: #e53935;
}

</style>
